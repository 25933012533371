<template>
  <div class="card">
    <h4 class="card-header">{{ activeLanguage.title }}</h4>
    <form class="card-body" @submit.prevent="onWorkflowSelection">
      <div class="form-group row" v-if="employee">
        <div class="col-3"></div>
        <div class="col-6">
          <div class="row">
            <label for="ordertype" class="col-sm-3 col-form-label">{{ activeLanguage.client }}</label>
            <div class="col-sm-9 high-z">
              <v-autocomplete
                :min-len="2"
                v-model="client"
                :items="clients"
                :get-label="getLabel"
                :component-item='itemTemplate'
                @update-items="updateClientList"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-3"></div>
        <div class="col-6">
          <div class="row">
            <label for="ordertype2" class="col-sm-3 col-form-label">{{ activeLanguage.workflow }}</label>
            <div class="col-sm-9">
              <v-autocomplete
                :disabled="employee && !client"
                :min-len="2"
                v-model="workflow"
                :items="workflows"
                :get-label="getWorkflowLabel"
                :component-item='itemWorkTemplate'
              />
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-12 text-center">
          <button class="btn" type="submit">{{ activeLanguage.btn }}</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import ClientItemTemplate from '../common/reusable/ClientItemTemplate.vue'
import CodeTemplate from './WorkflowItemTemplate'

export default {
  name: "SelectWorkflow",
  props: {
    activeLanguage: {
      required: true,
      default: function () {
        return {}
      }
    },
    employee: {
      required: true,
      default: false
    },
    userDetails: {
      required: true,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {
      clientSelection: null,
      workflowSelection: null,
      clients: [],
      workflows: [],
      client: null,
      workflow: null,
      itemTemplate: ClientItemTemplate,
      itemWorkTemplate: CodeTemplate,
    }
  },
  watch: {
    client: function (val) {
      if (val !== null) {
        val.id ? this.getWorkflow(val.id) : null
      }
    }
  },
  methods: {
    onWorkflowSelection() {
      if (this.workflow) {
        this.$emit('onWorkflowSelection', this.client, this.workflow)
        this.client = null
        this.workflow = null
      } else {
        EventBus.$emit('showAlertModal', 'Please enter correct data')
      }
    },
    getWorkflow(id) {
      let params = {}

      if (this.employee && !!id) {
        params.client = id
      }

      billinkAxios
      .get('app/client/get-workflow', {params})
      .then(({data}) => {
        this.workflows = []
        for (let item in data) {
          this.workflows.push({
            id: item,
            value: data[item]
          })
        }
      })
      .catch(err => console.error(err))
    },
    updateClientList(val) {
      billinkAxios
      .post('app/clients/list', {username: val})
      .then(({data}) => this.clients = data.clients)
      .catch(err => console.error(err))
    },
    getLabel(item) {
      return !!item ? item.username : ''
    },
    getWorkflowLabel(item) {
      return !!item ? item.value : ''
    },
  },
  mounted() {
    if (this.client === null) {
      this.getWorkflow(this.userDetails.id)
    }
  }
}
</script>

<style scoped>
.form-group {
  margin: 30px 0;
}

.high-z {
  z-index: 1000;
}
</style>
