<template>
  <div class="card">
    <h4 class="card-header">{{ activeLanguage.title }}</h4>
    <form class="card-body" @submit.prevent="createOrder">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group row">
            <label for="ordertype" class="col-sm-3 col-form-label">{{ activeLanguage.orderType }}</label>
            <div class="col-sm-9">
              <b-form-select
                :value="orderType"
                disabled
                id="ordertype"
              >
                <option value="Consumer">{{ activeLanguage.consumer }}</option>
                <option value="Business">{{ activeLanguage.business }}</option>
              </b-form-select>
            </div>
          </div>

          <div class="form-group row">
            <label for="invoicenumber" class="col-sm-3 col-form-label">{{ activeLanguage.invNum }}*</label>
            <div class="col-sm-9">
              <input
                v-model="form.invoice_number_clean"
                v-validate="'required'"
                :name="activeLanguage.invNum"
                type="text"
                class="form-control"
                id="invoicenumber"
              >
            </div>
          </div>

          <div class="form-group row">
            <label for="orderdate" class="col-sm-3 col-form-label">
              {{ activeLanguage.orderDate }}*
            </label>
            <div class="col-sm-9">
              <Datepicker
                v-model="form.order_date"
                v-validate="'required'"
                :name="activeLanguage.orderDate"
                :typeable="true"
                format="dd-MM-yyyy"
                initial-view="year"
                :calendar-button="true"
                calendar-button-icon="fa fa-calendar"
                input-class="form-control actual_range"
              />
            </div>
          </div>

          <div class="form-group row">
            <label for="initials" class="col-sm-3 col-form-label">{{ activeLanguage.initials }}</label>
            <div class="col-sm-9">
              <input
                :value="form.customer_initials"
                :disabled="!!creditCheckData.initials"
                type="text"
                class="form-control"
                id="initials"
              >
            </div>
          </div>

          <div class="form-group row">
            <label for="firstname" class="col-sm-3 col-form-label">{{ activeLanguage.firstName }}*</label>
            <div class="col-sm-9">
              <input
                :value="form.customer_first_name"
                :disabled="!!creditCheckData.firstname"
                v-validate="'required'"
                :name="activeLanguage.firstName"
                type="text"
                class="form-control"
                id="firstname"
              >
            </div>
          </div>

          <div class="form-group row">
            <label for="lastname" class="col-sm-3 col-form-label">{{ activeLanguage.lastName }}*</label>
            <div class="col-sm-9">
              <input
                :value="form.customer_last_name"
                :disabled="!!creditCheckData.surname"
                v-validate="'required'"
                :name="activeLanguage.lastName"
                type="text"
                class="form-control"
                id="lastname"
              >
            </div>
          </div>

          <div class="form-group row" v-if="orderType !== 'Business' || orderType !== 'Zakelijk'">
            <label for="orderdate" class="col-sm-3 col-form-label">
              {{ activeLanguage.birth }}*
            </label>
            <div class="col-sm-9">
              <Datepicker
                v-model="form.customer_birthdate"
                v-validate="'required'"
                :name="activeLanguage.orderDate"
                :typeable="true"
                format="dd-MM-yyyy"
                :disabled="!!creditCheckData.birthday"
                initial-view="year"
                :calendar-button="true"
                calendar-button-icon="fa fa-calendar"
                input-class="form-control actual_range"
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-3 col-form-label">{{ activeLanguage.sex }}*</label>
            <div class="col-sm-9">
              <div class="form-check form-check-inline">
                <label class="custom-control custom-checkbox">
                  <input
                    v-model="form.customer_sex"
                    v-validate="'required'"
                    :name="activeLanguage.sex"
                    value="mr"
                    type="radio"
                    class="custom-control-input"
                  >
                  <span class="custom-control-indicator"></span>
                  <span>{{ activeLanguage.mr }}</span>
                </label>
              </div>
              <div class="form-check form-check-inline">
                <label class="custom-control custom-checkbox">
                  <input
                    v-model="form.customer_sex"
                    :name="activeLanguage.sex"
                    value="mrs"
                    type="radio"
                    class="custom-control-input"
                  >
                  <span class="custom-control-indicator"></span>
                  <span>{{ activeLanguage.mrs }}</span>
                </label>
              </div>
              <div class="form-check form-check-inline">
                <label class="custom-control custom-checkbox">
                  <input
                    v-model="form.customer_sex"
                    :name="activeLanguage.sex"
                    value="unknown"
                    type="radio"
                    class="custom-control-input"
                  >
                  <span class="custom-control-indicator"></span>
                  <span>{{ activeLanguage.unknown }}</span>
                </label>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label for="e-mail" class="col-sm-3 col-form-label"> {{ activeLanguage.email }}*</label>
            <div class="col-sm-9">
              <input
                :value="form.customer_email"
                v-validate="'required|email'"
                :name="activeLanguage.email"
                :disabled="!!creditCheckData.email"
                type="text"
                class="form-control"
                id="e-mail"
              >
            </div>
          </div>

          <div class="form-group row">
            <label for="e-mail_2" class="col-sm-3 col-form-label"> {{ activeLanguage.email2 }}</label>
            <div class="col-sm-9">
              <input
                v-model="form.customer_email2"
                :name="activeLanguage.email2"
                type="text"
                class="form-control"
                id="e-mail_2"
              />
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group row" v-if="orderType === 'Business' || orderType === 'Zakelijk'">
            <label for="company" class="col-sm-3 col-form-label">{{ activeLanguage.company }}*</label>
            <div class="col-sm-9">
              <input
                :value="form.company_name"
                v-validate="'required'"
                :disabled="!!creditCheckData.company_name"
                :name="activeLanguage.company"
                type="text"
                class="form-control"
                id="company"
              >
            </div>
          </div>

          <div class="form-group row">
            <label for="phone" class="col-sm-3 col-form-label"> {{ activeLanguage.phone }}*</label>
            <div class="col-sm-9">
              <input
                :value="form.customer_phone_number"
                :disabled="!!creditCheckData.phone_number"
                v-validate="'required'"
                :name="activeLanguage.phone"
                type="text"
                class="form-control"
                id="phone"
              >
            </div>
          </div>

          <div class="form-group row">
            <label for="streetname" class="col-sm-3 col-form-label"> {{ activeLanguage.street }}*</label>
            <div class="col-sm-9">
              <input
                v-model="form.customer_street"
                v-validate="'required'"
                :name="activeLanguage.zip"
                type="text"
                class="form-control"
                id="streetname"
              >
            </div>
          </div>

          <div class="form-group row">
            <label for="housenumber" class="col-sm-3 col-form-label">{{ activeLanguage.houseNum }}*</label>
            <div class="col-sm-4">
              <input
                :value="form.customer_house_number"
                :disabled="!!creditCheckData.house_number"
                v-validate="'required'"
                :name="activeLanguage.houseNum"
                type="text"
                class="form-control"
                id="housenumber"
              >
            </div>
            <label for="addition" class="col-sm-2 col-form-label addition">{{ activeLanguage.addition }}</label>
            <div class="col-sm-3">
              <input
                :value="form.customer_house_extension"
                :disabled="!!creditCheckData.house_extension"
                type="text"
                class="form-control"
                id="addition"
              >
            </div>
          </div>

          <div class="form-group row">
            <label for="zipcode3" class="col-sm-3 col-form-label"> {{ activeLanguage.zip }}*</label>
            <div class="col-sm-9">
              <input
                :value="form.customer_postcode"
                v-validate="'required'"
                :disabled="!!creditCheckData.postcode"
                :name="activeLanguage.zip"
                type="text"
                class="form-control"
                id="zipcode3"
              >
            </div>
          </div>

          <div class="form-group row">
            <label for="city" class="col-sm-3 col-form-label"> {{ activeLanguage.city }}*</label>
            <div class="col-sm-9">
              <input
                v-model="form.customer_city"
                v-validate="'required'"
                :name="activeLanguage.city"
                type="text"
                class="form-control"
                id="city"
              />
            </div>
          </div>

          <div class="form-group row">
            <label for="country" class="col-sm-3 col-form-label"> {{ activeLanguage.country }}</label>
            <div class="col-sm-9">
              <b-form-select
                :value="form.customer_country_code"
                :disabled="!!creditCheckData.countrycode"
                :options="countries"
                id="country"
              />
            </div>
          </div>

          <div class="form-group row">
            <label for="text" class="col-sm-3 col-form-label"> {{ activeLanguage.addedText }}</label>
            <div class="col-sm-9">
              <textarea
                id="text"
                v-model="form.additional_text"
                :name="activeLanguage.addedText"
                class="form-control"
                rows="3"
              />
            </div>
          </div>

          <div class="form-group row">
            <label for="variable1" class="col-sm-3 col-form-label">{{ activeLanguage.variable1 }}</label>
            <div class="col-sm-9">
              <input
                v-model="form.variable1"
                type="text"
                class="form-control"
                id="variable1"
              >
            </div>
          </div>

          <div class="form-group row" v-if="orderType === 'Business' || orderType === 'Zakelijk'">
            <label for="kvk" class="col-sm-3 col-form-label"> {{ activeLanguage.kvk }}*</label>
            <div class="col-sm-9">
              <input
                :value="form.chamber_of_commerce_number"
                :disabled="!!creditCheckData.chamber_of_commerce_number"
                v-validate="'required'"
                :name="activeLanguage.kvk"
                type="text"
                class="form-control"
                id="kvk"
              >
            </div>
          </div>

          <div class="form-group row" v-if="orderType === 'Business' || orderType === 'Zakelijk'">
            <label for="btw" class="col-sm-3 col-form-label"> {{ activeLanguage.btw }}*</label>
            <div class="col-sm-9">
              <input
                v-model="form.vat_number"
                v-validate="'required'"
                :name="activeLanguage.btw"
                type="text"
                class="form-control"
                id="btw"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <br/>
          <div class="form-group row">
            <div class="col-sm-3"></div>
            <div class="col-sm-9">
              <label class="custom-control custom-checkbox">
                <input @change="differentDeliveryAddressHandler" type="checkbox" class="custom-control-input">
                <span class="custom-control-indicator"></span>
                <span>{{ activeLanguage.difDelAdd }}</span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <template v-if="showDifferentDeliveryAddress">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group row">
              <label for="firstname_a" class="col-sm-3 col-form-label">{{ activeLanguage.firstName }}*</label>
              <div class="col-sm-9">
                <input
                  v-model="form.delivery_address_first_name"
                  v-validate="'required'"
                  :name="activeLanguage.firstName"
                  type="text"
                  class="form-control"
                  id="firstname_a"
                >
              </div>
            </div>
            <div class="form-group row">
              <label for="lastname_a" class="col-sm-3 col-form-label">{{ activeLanguage.lastName }}*</label>
              <div class="col-sm-9">
                <input
                  v-model="form.delivery_address_last_name"
                  v-validate="'required'"
                  :name="activeLanguage.lastName"
                  type="text"
                  class="form-control"
                  id="lastname_a"
                >
              </div>
            </div>
            <div class="form-group row">
              <label for="housenumber_a" class="col-sm-3 col-form-label">{{ activeLanguage.houseNum }}*</label>
              <div class="col-sm-4">
                <input
                  v-model="form.customer_delivery_house_number"
                  v-validate="'required'"
                  :name="activeLanguage.houseNum"
                  type="text"
                  class="form-control"
                  id="housenumber_a"
                />
              </div>
              <label for="addition_a" class="col-sm-2 col-form-label addition">{{ activeLanguage.addition }}</label>
              <div class="col-sm-3">
                <input
                  v-model="form.customer_delivery_house_extension"
                  :name="activeLanguage.addition"
                  type="text"
                  class="form-control"
                  id="addition_a"
                />
              </div>
            </div>
            <div class="form-group row">
              <label for="zipcode3_a" class="col-sm-3 col-form-label">{{ activeLanguage.zip }}*</label>
              <div class="col-sm-9">
                <input
                  v-model="form.customer_delivery_postcode"
                  v-validate="'required'"
                  :name="activeLanguage.zip"
                  type="text"
                  class="form-control"
                  id="zipcode3_a"
                />
              </div>
            </div>
            <div class="form-group row">
              <label for="streetname_a" class="col-sm-3 col-form-label">{{ activeLanguage.street }}*</label>
              <div class="col-sm-9">
                <input
                  v-model="form.customer_delivery_street"
                  v-validate="'required'"
                  :name="activeLanguage.street"
                  type="text"
                  class="form-control"
                  id="streetname_a"
                />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group row">
              <label for="initials_a" class="col-sm-3 col-form-label">{{ activeLanguage.initials }}</label>
              <div class="col-sm-9">
                <input
                  v-model="form.customer_delivery_initials"
                  :disabled="!!creditCheckData.chamber_of_commerce_number"
                  v-validate="'required'"
                  :name="activeLanguage.initials"
                  type="text"
                  class="form-control"
                  id="initials_a"
                />
              </div>
            </div>
            <div class="form-group row">
              <label for="country_a" class="col-sm-3 col-form-label">{{ activeLanguage.country }}</label>
              <div class="col-sm-9">
                <b-form-select
                  v-model="form.customer_delivery_country_code"
                  :options="countries"
                  id="country_a"
                />
              </div>
            </div>
            <div class="form-group row">
              <label for="city_a" class="col-sm-3 col-form-label">{{ activeLanguage.city }}*</label>
              <div class="col-sm-9">
                <input
                  v-model="form.customer_delivery_city"
                  v-validate="'required'"
                  :name="activeLanguage.city"
                  type="text"
                  class="form-control"
                  id="city_a"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
      <br/>
      <button type="submit" class="btn float-right">{{ activeLanguage.step4Btn }}</button>
    </form>
  </div>
</template>

<script>
// import Datepicker from 'vuejs-datepicker';  wait until format + typeable is fixed
import Datepicker2 from 'vuejs-datepicker';
import Datepicker from '../common/reusable/datepicker/components/Datepicker';
import moment from 'moment';

export default {
  name: "create-order",
  props: {
    activeLanguage: {
      required: true,
      default: function () {
        return {}
      }
    },
    countries: {
      default: function () {
        return []
      }
    },
    creditCheckData: {}
  },
  data() {
    return {
      orderType: 'Consumer',
      showDifferentDeliveryAddress: false,
      form: {},
    }
  },
  methods: {
    createOrder() {
      this.$validator
      .validate()
      .then(res => {
        if (!!res) {
          if (!this.showDifferentDeliveryAddress) {
            this.form.delivery_address_first_name = this.form.customer_first_name
            this.form.delivery_address_last_name = this.form.customer_last_name
            this.form.customer_delivery_house_number = this.form.customer_house_number
            this.form.customer_delivery_house_extension = this.form.customer_house_extension
            this.form.customer_delivery_initials = this.form.customer_initials
            this.form.delivery_address_company_name = this.form.company_name
            this.form.customer_delivery_postcode = this.form.customer_postcode
            this.form.customer_delivery_street = this.form.customer_street
            this.form.customer_delivery_city = this.form.customer_city
            this.form.customer_delivery_country_code = this.form.customer_country_code
          }
          this.form.order_type = this.orderType === 'Consumer' || this.orderType === 'Zakelijk' ? 'Private' : 'Company'
          this.form.order_date = moment(this.form.order_date).format('DD-MM-YYYY')
          this.form.customer_birthdate = moment(this.form.customer_birthdate).format('DD-MM-YYYY')

          this.$emit('createOrder', {...this.form})
          this.resetForm(this.creditCheckData)
        } else {
          let errMes = this.errors.items.map(item => item.msg + '\n')
          EventBus.$emit('showAlertModal', errMes.join().replace(/,/g, ''))
        }
      });
    },
    differentDeliveryAddressHandler(e) {
      this.showDifferentDeliveryAddress = e.target.checked
      this.form.customer_delivery_country_code = this.countries[0].value
    },
    findCountryByTag(tag) {
      if (!!this.countries[0]) {
        let country = this.countries.find(item => item.value === tag)
        return !!country ? country.value : this.countries[0].value
      }
    },
    resetForm(val = {}) {
      this.orderType = val.order_type === 'B' ? 'Business' : 'Consumer'
      this.form = {
        additional_text: "",
        company_name: val.company_name,
        customer_birthdate: this.getProperDate(val.birthday),
        customer_city: "",
        customer_country_code: this.findCountryByTag(val.countrycode),
        customer_email: val.email,
        customer_first_name: val.firstname,
        customer_house_extension: val.house_extension,
        customer_house_number: val.house_number,
        customer_initials: val.initials,
        customer_last_name: val.surname,
        customer_postcode: val.postcode,
        customer_sex: "",
        customer_street: "",
        delivery_address_company_name: "",
        delivery_address_first_name: "",
        delivery_address_last_name: "",
        invoice_number_clean: "",
        order_date: new Date(),
        order_type: this.orderType === 'Consumer' || this.orderType === 'Zakelijk' ? 'Private' : 'Company',
        variable1: "",
        chamber_of_commerce_number: val.chamber_of_commerce_number,
        vat_number: '',
        customer_email2: '',
        customer_phone_number: val.phone_number,
        customer_delivery_city: "",
        customer_delivery_country_code: 'NL',
        customer_delivery_house_extension: '',
        customer_delivery_house_number: '',
        customer_delivery_initials: "",
        customer_delivery_postcode: '',
        customer_delivery_street: "",
      }

      if (val.deliveryData) {
        this.showDifferentDeliveryAddress = true
        let delData = {
          customer_delivery_city: "",
          customer_delivery_country_code: val.delivery_countrycode,
          customer_delivery_house_extension: val.delivery_house_extension,
          customer_delivery_house_number: val.delivery_house_number,
          customer_delivery_initials: "",
          customer_delivery_postcode: val.delivery_postcode,
          customer_delivery_street: "",
        }
        this.form = {...this.form, ...delData}
      }

      this.$forceUpdate()
    },
    getProperDate(date) {
      let newDate = ''
      if (date) {
        let dateFrom = date.split('-')
        newDate = new Date(dateFrom[2], dateFrom[1] - 1, dateFrom[0])
      }
      return newDate
    }
  },
  mounted() {
    this.resetForm()
  },
  watch: {
    countries: function (val) {
      this.form.customer_country_code = val[0].value
    },
    creditCheckData: function (val) {
      this.resetForm(val)
    }
  },
  components: {
    Datepicker,
    Datepicker2
  }
}
</script>

<style scoped lang="scss">
label {
  cursor: pointer;
  font-size: 13px;

  span {
    vertical-align: middle;
  }

  .custom-control-indicator {
    top: .1rem;
  }
}

.form-check {
  margin-top: 6px;

  label {
    min-height: auto;
    margin-bottom: 0;
  }
}
</style>
<style>
.form-group .vdp-datepicker .form-control[disabled] {
  background-color: #e9ecef;
}
</style>