<template>
    <form @submit.prevent="close" class="card" v-if="show">
        <template v-if="showMes === 'accept'">
            <h4 class="card-header">{{ activeLanguage.accept.title }}</h4>
            <div class="card-body">
                <div class="accepted">
                    <i class="fa fa-check-circle" aria-hidden="true"></i>
                    <p>{{ activeLanguage.accept.txt }}</p>
                </div>
                <button type="submit" class="btn float-right">{{ activeLanguage.accept.btn }}</button>
            </div>
        </template>
        <template v-if="showMes === 'decline'">
            <h4 class="card-header">{{ activeLanguage.decline.title }}</h4>
            <div class="card-body">
                <div class="declined">
                    <i class="fa fa-times-circle" aria-hidden="true"></i>
                    <p>{{ activeLanguage.decline.txt }}</p>
                </div>
                <button type="submit" class="btn float-right">{{ activeLanguage.decline.btn }}</button>
            </div>
        </template>
        <template v-if="showMes === 'confirm'">
            <h4 class="card-header">{{ activeLanguage.confirm.title }}</h4>
            <div class="card-body">
                <div class="accepted">
                    <i class="fa fa-check-circle" aria-hidden="true"></i>
                    <p>{{ activeLanguage.confirm.txt }}</p>
                </div>
                <div class="text-center">
                    <button type="submit" class="btn">{{ activeLanguage.confirm.btn }}</button><br />
                </div>
                <br />
            </div>
        </template>
    </form>
</template>

<script>
	export default {
		name: "new-order-popup",
		props: {
			activeLanguage: {
				required: true,
				default: function() { return { accept: {}, decline: {}, confirm: {},}}
			}
		},
        data() {
			return {
				show: false,
                showMes: ''
            }
        },
        methods: {
	        open(mesStatus) {
		        this.show = true
                this.showMes = mesStatus
	        },
	        close() {
		        this.show = false
                this.$emit('popupClosed', this.showMes)
	        }
        }
	}
</script>

<style scoped>

</style>