<template>
  <div class="card">
    <h4 class="card-header">{{ activeLanguage.title }}</h4>
    <form class="card-body" @submit.prevent="addProducts">
      <AddNewProductTable
        :activeLanguage="activeLanguage"
        @onNewProduct="onNewProduct"
      />
      <template v-if="!!products.length">
        <h3>{{ activeLanguage.overview }}</h3>
        <table class="table">
          <thead>
          <tr>
            <th width="5%"></th>
            <th width="10%">{{ activeLanguage.table.code }}</th>
            <th>{{ activeLanguage.table.description }}</th>
            <th width="15%">{{ activeLanguage.table.quantity }}</th>
            <th width="15%">{{ activeLanguage.table.priceExVat }}</th>
            <th class="text-right">{{ activeLanguage.table.total }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(product, index) in products">
            <td class="text-center" @click.prevent="removeProduct(index)">
              <a href="" class="remove-link"><i class="fa fa-times-circle" aria-hidden="true"></i></a>
            </td>
            <td>{{ product.code }}</td>
            <td>{{ product.description }}</td>
            <td>{{ product.order_quantity }}</td>
            <td class="amount"><span>€</span> {{ product.price_excluding_vat | numberFormat(2) }}</td>
            <td class="amount"><span>€</span> {{ product.price_including_vat | numberFormat(2) }}</td>
          </tr>
          </tbody>
          <tfoot>
          <tr>
            <td></td>
            <td></td>
            <td class="border-top border-bottom" colspan="3"><strong>{{ activeLanguage.totalWithoutVat }}</strong></td>
            <td class="text-right border-top border-bottom amount">
              <strong><span>€</span>{{ totalWithoutVat | numberFormat(2) }}</strong></td>
          </tr>
          <tr v-for="vat in vats">
            <td></td>
            <td></td>
            <td class="text-left border-bottom" colspan="3">
              <strong>
                {{ `${activeLanguage.table.vat}` }}
                {{ vat.percentage | percentageFormat }}
              </strong>
            </td>
            <td class="text-right border-bottom amount">
              <strong>
                <span>€</span>{{ vat.val | numberFormat(2) }}
              </strong>
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td class="text-left" colspan="3">
              <strong>
                {{ `${activeLanguage.balance} €` }}
                {{ totalVatValue | numberFormat(2) }}
                )
              </strong>
            </td>
            <td class="text-right amount"><strong><span>€</span>{{ totalWithVat | numberFormat(2) }}</strong></td>
          </tr>
          </tfoot>
        </table>
      </template>
      <br/>
      <button type="submit" class="btn float-right">{{ activeLanguage.step5 }}</button>
    </form>
  </div>
</template>

<script>
import AddNewProductTable from './AddProductTable'

export default {
  name: "add-products",
  props: {
    activeLanguage: {
      required: true,
      default: function () {
        return {}
      }
    },
  },
  data() {
    return {
      products: [],
      vats: [],
      totalVatValue: 0,
      totalWithoutVat: 0,
      totalWithVat: 0,
    }
  },
  watch: {
    products: function (val) {
      this.vats = []
      this.totalWithVat = 0
      this.totalWithoutVat = 0
      this.totalVatValue = 0

      val.forEach(item => {
        let singlePrice = parseFloat(item.price_excluding_vat),
          quantity = parseFloat(item.order_quantity),
          priceWithVat = item.price_including_vat,
          vat = parseFloat(item.vat).toFixed(2),
          priceWithoutVat = (quantity * singlePrice),
          vatVal = priceWithVat - priceWithoutVat

        if (+vat !== 1) {
          this.setVats(vat, vatVal)
        }

        this.totalWithoutVat += priceWithoutVat
        this.totalVatValue += priceWithVat - priceWithoutVat
        this.totalWithVat = this.totalWithoutVat + this.totalVatValue
      })
    }
  },
  methods: {
    setVats(vat, vatVal) {
      let item = this.vats.find(item => item.percentage === vat)

      if (!!item) {
        let index = this.vats.indexOf(item)
        this.vats[index].val += vatVal
      } else {
        this.vats.push({
          percentage: vat,
          val: vatVal
        })
      }
    },
    onNewProduct(product) {
      this.products.push(product)
    },
    removeProduct(index) {
      this.products.splice(index, 1)
    },
    convertVat(products) {
      let vatOptions = {
        "1": "0",
        "1.06": "0.06",
        "1.07": "0.07",
        "1.09": "0.09",
        "1.12": "0.12",
        "1.19": "0.19",
        "1.20": "0.20",
        "1.21": "0.21"
      }
      products.forEach(val => {
        val.vat = vatOptions[val.vat]
        val.price_including_vat = val.piece_price_including_vat
      })
      return products
    },
    addProducts() {
      if (!this.products.length) {
        EventBus.$emit('showAlertModal', 'Please add products to proceed')
        return false
      }
      this.products = this.convertVat(this.products)
      this.$emit('addProducts', this.products)
      this.products = []
      this.vats = []
      this.totalWithVat = 0
      this.totalWithoutVat = 0
      this.totalVatValue = 0
    }
  },
  filters: {
    percentageFormat: function (value = '') {
      return `${value.toString().replace(/1.0|1./, '')}%`
    }
  },
  components: {
    AddNewProductTable,
  },
}
</script>

<style scoped lang="scss">
@media (max-width: 480px) {
  .text-right {
    text-align: center !important;
  }
}

.table {
  th, td {
    border: none;
  }
}

tfoot {
  color: inherit;
  background: none;

  td {
    padding: .75rem !important;
    text-align: left;
  }
}
</style>