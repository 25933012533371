<template>
    <form @submit.prevent="onCreditCheckSubmit" class="card">
        <h4 class="card-header">
            {{ activeLanguage.title }}
            <div class="testing-box" v-if="showBackdoor">
                <strong>Backdoor(only for test)</strong>
                <div class="form-check form-check-inline">
                    <label class="custom-control custom-checkbox">
                        <input
                            v-model="backdoor"
                            value="1"
                            type="radio"
                            class="custom-control-input"
                        >
                        <span class="custom-control-indicator"></span>
                        <span>Always accept</span>
                    </label>
                </div>
                <div class="form-check form-check-inline">
                    <label class="custom-control custom-checkbox">
                        <input
                            v-model="backdoor"
                            value="0"
                            type="radio"
                            class="custom-control-input"
                        >
                        <span class="custom-control-indicator"></span>
                        <span>Always refuse</span>
                    </label>
                </div>
                <div class="form-check form-check-inline">
                    <label class="custom-control custom-checkbox">
                        <input
                            v-model="backdoor"
                            value="2"
                            type="radio"
                            class="custom-control-input"
                        >
                        <span class="custom-control-indicator"></span>
                        <span>Off</span>
                    </label>
                </div>
            </div>
        </h4>
        <div class="card-body">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group row">
                        <label for="ordertype" class="col-sm-3 col-form-label">{{ activeLanguage.orderType }}</label>
                        <div class="col-sm-9">
                            <b-form-select v-model="orderType" id="ordertype">
                                <option value="Consumer">{{ activeLanguage.consumer }}</option>
                                <option value="Business">{{ activeLanguage.business }}</option>
                            </b-form-select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="name" class="col-sm-3 col-form-label">{{ activeLanguage.name }}*</label>
                        <div class="col-sm-9">
                            <input
                                v-model="form.firstname"
                                v-validate="'required'"
                                :name="activeLanguage.name"
                                type="text"
                                class="form-control"
                                id="name"
                            >
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="surname" class="col-sm-3 col-form-label">{{ activeLanguage.surname }}*</label>
                        <div class="col-sm-9">
                            <input
                                v-model="form.surname"
                                v-validate="'required'"
                                :name="activeLanguage.surname"
                                type="text"
                                class="form-control"
                                id="surname"
                            >
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="housenumber" class="col-sm-3 col-form-label">{{ activeLanguage.houseNumber }}*</label>
                        <div class="col-sm-4">
                            <input
                                v-model="form.house_number"
                                v-validate="'required'"
                                :name="activeLanguage.houseNumber"
                                type="text"
                                class="form-control"
                                id="housenumber"
                            >
                        </div>
                        <label for="addition" class="col-sm-2 col-form-label addition">{{ activeLanguage.addition }}</label>
                        <div class="col-sm-3">
                            <input
                                v-model="form.house_extension"
                                type="text"
                                class="form-control"
                                id="addition"
                            >
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="zipcode" class="col-sm-3 col-form-label">{{ activeLanguage.zip }}*</label>
                        <div class="col-sm-9">
                            <input
                                    v-model="form.postcode"
                                    v-validate="'required'"
                                    :name="activeLanguage.zip"
                                    type="text"
                                    class="form-control"
                                    id="zipcode"
                            >
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="telephone" class="col-sm-3 col-form-label">{{ activeLanguage.phone }}*</label>
                        <div class="col-sm-9">
                            <input
                                    v-model="form.phone_number"
                                    v-validate="'required|phone'"
                                    :name="activeLanguage.phone"
                                    type="text"
                                    class="form-control"
                                    id="phone"
                            >
                        </div>
                    </div>
                    <div class="form-group row" v-if="orderType === 'Business' || orderType === 'Zakelijk'">
                        <label for="companyName" class="col-sm-3 col-form-label">{{ activeLanguage.companyName }}*</label>
                        <div class="col-sm-9">
                            <input v-model="form.company_name" type="text" class="form-control" id="companyName">
                        </div>
                    </div>
                    <br />
                    <div class="form-group row">
                        <div class="col-sm-3"></div>
                        <div class="col-sm-9">
                            <label class="custom-control custom-checkbox">
                                <input @change="differentDeliveryAddressHandler" type="checkbox" class="custom-control-input">
                                <span class="custom-control-indicator"></span>
                                <span>{{ activeLanguage.difDelAdd }}</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group row">
                        <label for="orderamount" class="col-sm-3 col-form-label">{{ activeLanguage.orderAmount }}*</label>
                        <div class="col-sm-9">
                          <InputPrice
                            :name="activeLanguage.orderAmount"
                            v-model="form.order_amount"
                            v-validate="{ required: true, is_not: '€ 0' }"
                          />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="initials" class="col-sm-3 col-form-label">{{ activeLanguage.initials }}</label>
                        <div class="col-sm-9">
                            <input v-model="form.initials" type="text" class="form-control" id="initials">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="date" class="col-sm-3 col-form-label">
                            {{ activeLanguage.birth }}
                            {{ orderType === 'Business' || orderType === 'Zakelijk' ? '' : '*' }}
                        </label>

                        <div class="input-group col-sm-9">
                            <Datepicker
                                format="dd-MM-yyyy"
                                v-model="form.birthday"
                                v-validate="orderType === 'Business' || orderType === 'Zakelijk' ? 'dateOfBirth' : 'required|dateOfBirth'"
                                :name="activeLanguage.birth"
                                placeholder=""
                                :disabled-dates="disabledDates"
                                initial-view="year"
                                :calendar-button="true"
                                :typeable="true"
                                calendar-button-icon="fa fa-calendar"
                                input-class="form-control actual_range"
                                :openDate="disabledDates.from"
                            />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="country" class="col-sm-3 col-form-label">{{ activeLanguage.country }}</label>
                        <div class="col-sm-9">
                            <!--<input v-model="form.country" type="text" class="form-control" id="country">-->
                            <b-form-select v-model="form.country_code" :options="countries" id="country" />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="e-mail" class="col-sm-3 col-form-label">{{ activeLanguage.email }}*</label>
                        <div class="col-sm-9">
                            <input
                                    v-model="form.email"
                                    v-validate="'required|email'"
                                    :name="activeLanguage.email"
                                    type="email"
                                    class="form-control"
                                    id="e-mail"
                            >
                        </div>
                    </div>
                    <div class="form-group row" v-if="orderType === 'Business' || orderType === 'Zakelijk'">
                        <label for="kvk" class="col-sm-3 col-form-label">{{ activeLanguage.kvk }}*</label>
                        <div class="col-sm-9">
                            <input
                                    v-model="form.chamber_of_commerce_number"
                                    v-validate="'required'"
                                    :name="activeLanguage.kvk"
                                    type="text"
                                    class="form-control"
                                    id="kvk"
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-if="showDifferentDeliveryAddress">
                <div class="col-md-6">
                    <div class="form-group row">
                        <label for="housenumber_a" class="col-sm-3 col-form-label">{{ activeLanguage.houseNumber }}*</label>
                        <div class="col-sm-4">
                            <input
                                type="text"
                                v-model="form.delivery_house_number"
                                v-validate="'required'"
                                :name="'Delievery ' + activeLanguage.houseNumber"
                                class="form-control"
                                id="housenumber_a"
                            >
                        </div>
                        <label for="addition_a" class="col-sm-2 col-form-label addition">{{ activeLanguage.addition }}</label>
                        <div class="col-sm-3">
                            <input type="text" v-model="form.delivery_house_extension" class="form-control" id="addition_a">
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group row">
                        <label for="zipcode2_a" class="col-sm-3 col-form-label">{{ activeLanguage.zip }}*</label>
                        <div class="col-sm-9">
                            <input
                                type="text"
                                v-model="form.delivery_postcode"
                                v-validate="'required'"
                                :name="'Delievery ' + activeLanguage.zip"
                                class="form-control"
                                id="zipcode2_a"
                            >
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="country_a" class="col-sm-3 col-form-label">{{ activeLanguage.country }}</label>
                        <div class="col-sm-9">
                            <!--<input v-model="form.country" type="text" class="form-control" id="country">-->
                            <b-form-select v-model="form.delivery_country_code" :options="countries" id="country_a" />
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <button type="submit" class="btn float-right">{{ activeLanguage.step2Btn }}</button>
        </div>
    </form>
</template>

<script>
    // import Datepicker from 'vuejs-datepicker';  wait until format + typeable is fixed
    import Datepicker from '../common/reusable/datepicker/components/Datepicker';
    import moment from 'moment'
    import InputPrice from "../common/reusable/InputPrice.vue";

    export default {
		name: "credit-check",
        props: {
	        activeLanguage: {
	        	required: true,
                default: function() { return {}}
            },
	        countries: {
	        	default: function() { return []}
            }
        },
        data() {
			return {
				showBackdoor: process.env.VUE_APP_TEST_ENV || false,
				backdoor: "2",
				orderType: 'Consumer',
				showDifferentDeliveryAddress: false,
				form: {},
				disabledDates: {
					from: new Date(new Date().getFullYear() - 16, new Date().getMonth(), new Date().getDate() - 1),
                }
            }
        },
        watch: {
	        countries: function(val) {
	        	this.form.country_code = val[0].value
            },
        },
        methods: {
	        differentDeliveryAddressHandler(e) {
	        	this.showDifferentDeliveryAddress = e.target.checked
		        this.form.delivery_country_code = this.countries[0].value
            },
	        onCreditCheckSubmit() {
		        this.$validator
                    .validate()
                    .then(res => {
                    	if (!!res) {
                    		let deliveryData = false  //check if delivery data in entered
                    		if (!this.showDifferentDeliveryAddress) {
			                    this.form.delivery_house_number = this.form.house_number
			                    this.form.delivery_house_extension = this.form.house_extension
			                    this.form.delivery_postcode = this.form.postcode
			                    this.form.delivery_country_code = this.form.country_code
                            } else {
			                    deliveryData = true
                            }
                            this.form.order_type = this.orderType === 'Consumer' || this.orderType === 'Zakelijk' ? 'Private' : 'Company'
		                    this.form.order_amount = this.form.order_amount.replace('€ ', '')

                            if (!!this.form.birthday) {
			                    this.form.birthday = moment(this.form.birthday).format('DD-MM-YYYY')
                            } else {
			                    this.form.birthday = ''
                            }

                            if (parseFloat(this.backdoor) !== 2) {
                    			this.form.backdoor = this.backdoor // only for dev server
                            } else {
                    			delete this.form.backdoor
                            }

                            this.$emit('creditCheck', this.form, deliveryData)
                            this.resetForm()
                        } else {
		                    let errMes = this.errors.items.map(item => item.msg + '\n')
		                    EventBus.$emit('showAlertModal', errMes.join().replace(/,/g, ''))
                        }
                    });
            },
            resetForm() {
	        	this.form = {
			        birthday: '',
			        country_code: this.countries.length ? this.countries[0].value : 'NL',
			        company_name: '',
			        delivery_country_code: '',
			        delivery_house_number: '',
			        delivery_postcode: '',
			        delivery_house_extension: '',
			        email: '',
			        firstname: '',
			        house_number: '',
			        order_amount: '',
			        order_type: '',
			        phone_number: '',
			        postcode: '',
			        surname: '',
			        initials: '',
			        chamber_of_commerce_number: '',
			        house_extension: ''
		        }
	            this.backdoor = '2'
            },
        },
	    mounted() {
		    this.resetForm()
	    },
        components: {
          InputPrice,
            Datepicker,
        }
	}
</script>

<style scoped lang="scss">
    label {
        cursor: pointer;
        font-size: 13px;
        span {
            vertical-align: middle;
        }
        .custom-control-indicator {
            top: .1rem;
        }
    }
    .testing-box {
        float: right;
        background-color: red;
        padding: 2px;
        padding-bottom: 0px;
        margin: -6px;
        position: relative;
        top: 4px;
        strong {
            margin-right: 10px;
            margin-top: -4px;
            position: relative;
            top: -2px;
            font-size: 14px;
        }
        .custom-control {
            margin-bottom: 0;
            min-height: auto;
            .custom-control-indicator {
                top: 0px;
            }
        }
    }
</style>
