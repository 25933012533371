<template>
    <div class="main newOrder">
        <div class="container">
            <div class="title">
                <div class="links float-right">
                    <router-link to="/orders" class="back-link">
                        <i class="fa fa-list" aria-hidden="true"></i>
                        {{ activeLanguage.back }}
                    </router-link>
                </div>
                <h1>
                    {{ activeLanguage.title }}
                    <!--<a @click.prevent="toggleHelpSidebar" href="#"><i class="fa fa-question-circle question" aria-hidden="true"></i></a>-->
                </h1>
            </div>
            <SelectWorkflow
                @onWorkflowSelection="workflowSelection"
                :employee="manageOrdersPermission"
                :activeLanguage="activeLanguage.selectWorkflow"
                :userDetails="userDetails"
                v-show="stepToShow === 1"
            />
            <CreditCheck
                @creditCheck="onCreditCheck"
                :activeLanguage="activeLanguage.creditCheck"
                :countries="countries"
                v-show="stepToShow === 2"
            />
            <CreateOrder
                @createOrder="onCreateOrder"
                :activeLanguage="activeLanguage.createOrder"
                :countries="countries"
                v-show="stepToShow === 3"
                :creditCheckData="creditCheckData"
            />
            <AddProducts
                @addProducts="onAddProducts"
                :activeLanguage="activeLanguage.addProducts"
                v-show="stepToShow === 4"
            />
            <NewOrderPopup
                ref="newOrderPopup"
                :activeLanguage="activeLanguage.resultCreditCheck"
                @popupClosed="onPopupClosed"
            />
        </div>
    </div>
</template>

<script>
	import countries from 'i18n-iso-countries'

    import CreditCheck from '@/components/newOrderView/CreditCheck.vue'
    import CreateOrder from '@/components/newOrderView/CreateOrder.vue'
    import AddProducts from '@/components/newOrderView/AddProduct/AddProducts.vue'
    import NewOrderPopup from '@/components/newOrderView/NewOrderPopup.vue'
    import SelectWorkflow from '@/components/newOrderView/SelectWorkflow.vue'

    import {mapGetters} from  "vuex"

	export default {
		name: "order-new",
        data() {
			return {
				stepToShow: 0,
				countries: [],
				client: {},
				workflow: {},
                form: {},
                uuid: null,
                creditCheckData: {},
                vatOptionsToBack: {
                    '0': "0",
					'0.06': "6",
					'0.07': "7",
					'0.09': "9",
					'0.12': "12",
					'0.19': "19",
					'0.20': "20",
					'0.21': "21"
                }
            }
        },
        components: {
	        CreditCheck,
	        CreateOrder,
	        AddProducts,
	        NewOrderPopup,
	        SelectWorkflow
        },
        computed: {
        ...mapGetters({
                activeLanguage: 'getOrderNewLanguage',
	            manageOrdersPermission: 'userManage_orders',
                userDetails: 'getBillinkUserDetails'
            })
        },
        watch: {
	        activeLanguage: function() { this.setCountrySelection() }
        },
        methods: {
	        workflowSelection(client, workflow) {
	        	this.client = client || {}
	        	this.workflow = workflow || {}
		        this.creditCheckData = {}
		        this.form = {}
		        this.uuid = null
		        this.goToStep(2)
            },
	        onCreditCheck(commonData, deliveryData) {
		        let params = {
                    username: this.client.username || this.userDetails.username,
			        client_id: this.client.id || this.userDetails.id,
			        workflow_id: this.workflow.id,
                    ...commonData
		        }

		        billinkAxios
			        .post('app/customer/credit-check', params)
			        .then(({data}) => {
                        if (!!data.advice) {
				        	this.creditCheckData = { ...commonData, deliveryData }
				        	this.uuid = data.uuid
                            this.$refs.newOrderPopup.open('accept')
                        } else {
	                        this.creditCheckData = {}
	                        this.$refs.newOrderPopup.open('decline')
                        }
                        this.goToStep(0)
			        })
			        .catch(err => console.error(err))
            },
            goToStep(step) {
	        	this.stepToShow = step
            },
	        onPopupClosed(status) {
	        	if (status === 'confirm') {
			        this.goToStep(1)
                } else if (status === 'decline') {
			        this.goToStep(1)
                } else if (status === 'accept') {
			        this.goToStep(3)
                }
            },
	        onCreateOrder(data) {
	        	this.form = { ...data }
		        this.goToStep(4)
            },
	        onAddProducts(items) {
                this.form.items = [...items]
                this.form.items.forEach(item => {
                    item.vat = this.vatOptionsToBack[item.vat]
                })
                this.sendOrder()
            },
            vatFormat(val) {
                return parseInt(val.toString().replace(/1.0|1./, ''))
            },
            sendOrder() {
	        	let params = {
			        client_id: this.client.id || this.userDetails.id,
			        workflow_id: this.workflow.id,
			        uuid: this.uuid,
	        		...this.form
	        	}

	            billinkAxios
		            .post('app/order/save-info', params)
		            .then(({data}) => {
			            this.resetData()
			            this.goToStep(0)
			            this.$refs.newOrderPopup.open('confirm')
		            })
		            .catch(err => {
			            console.error(err)
			            if (err.response.data.message) {
                                EventBus.$emit('showAlertModal', err.response.data.message)
                            }
			            this.goToStep(3)
		            })
            },
	        setCountrySelection() {
		        countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
		        countries.registerLocale(require("i18n-iso-countries/langs/nl.json"));

		        let locale = localStorage.getItem('localization'),
			        localeType = locale === 'english' ? 'en' : 'nl',
			        countryListArr = [],
			        countryList = countries.getNames(localeType),
			        country = {
				        value: "NL",
				        text: countries.getName("NL", localeType),
			        }

		        Object.keys(countries.getNames(localeType))
			        .forEach((item) => {
				        if (item !== country.value) {
					        countryListArr.push({
						        value: item,
						        text: countryList[item]
					        })
				        }
			        })

		        countryListArr.unshift(country)
		        this.countries = countryListArr
	        },
	        toggleHelpSidebar() {
		        this.$store.dispatch('toggleHelpModal')
	        },
            resetData() {
	            this.form = {}
	            this.creditCheckData = {}
	            this.client = {}
	            this.workflow = {}
	            this.uuid = null
            }
        },
        mounted() {
            this.setCountrySelection()
	        this.goToStep(1)
        }
	}
</script>

<style scoped>
@media (max-width: 480px) {
    h1 {
        padding-top: 20px;
        text-align: center;
    }
    .links.float-right{
        float: none !important;
        text-align: center;
    }
}
</style>