<template>
  <div>
    <div class="form-row">
      <div class="form-group col-md-2">
        <label for="code" class="col-form-label">{{ activeLanguage.table.code }}</label>
        <input
          v-model="form.code"
          v-validate="'required'"
          :name="activeLanguage.table.code"
          type="text"
          class="form-control"
          id="code"
        />
      </div>
      <div class="form-group col-md-4">
        <label for="omschrijving" class="col-form-label">{{ activeLanguage.table.description }}</label>
        <input
          v-model="form.description"
          v-validate="'required'"
          :name="activeLanguage.table.description"
          type="text"
          class="form-control"
          id="omschrijving"
        />
      </div>
      <div class="form-group col-md-1">
        <label for="aantal" class="col-form-label">{{ activeLanguage.table.quantity }}</label>
        <masked-input
          @input="totalPriceCalc"
          id="aantal"
          v-validate="{ required: true, is_not: '0' }"
          :name="activeLanguage.table.quantity"
          type="text"
          class="form-control"
          v-model="form.order_quantity"
          :mask="numMask"
          :guide="false"
        />
      </div>
      <div class="form-group col-md-1">
        <label for="vat" class="col-form-label">{{ activeLanguage.table.vat }}</label>
        <b-form-select
          class="form-control"
          id="BTW"
          :name="activeLanguage.table.vat"
          @input="totalPriceCalc"
          v-model="form.vat"
          :options="vatOptions"
        />
      </div>
      <div class="form-group col-md-2">
        <label class="col-form-label">{{ activeLanguage.table.priceIncVat }}</label>
        <InputPrice
          v-model="form.piece_price_including_vat"
          @input="totalPriceCalc"
        />
      </div>
      <div class="form-group col-md-2">
        <label class="col-form-label">{{ activeLanguage.table.total }}</label>
        <InputPrice
          :value="form.price_including_vat"
          disabled
        />
      </div>
    </div>
    <br/>
    <div class="text-right">
      <button @click.prevent="addToOrder" type="button" class="btn">{{ activeLanguage.addToOrder }}</button>
    </div>
    <br/>
  </div>
</template>

<script>
import MaskedInput from 'vue-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import accounting from 'accounting'
import InputPrice from "../../common/reusable/InputPrice.vue";

export default {
  name: "AddProductTable",
  props: {
    activeLanguage: {
      required: true,
      default: function () {
        return {}
      }
    },
  },
  data() {
    return {
      form: {},
      currencyMask: createNumberMask({
        prefix: ' € ',
        allowDecimal: true,
        allowNegative: true,
        decimalLimit: 5,
        thousandsSeparatorSymbol: '.',
        decimalSymbol: ','
      }),
      numMask: createNumberMask({
        prefix: '',
        allowDecimal: false
      }),
      vatOptions: [
        {text: '0%', value: "1"},
        {text: '6%', value: "1.06"},
        {text: '7%', value: "1.07"},
        {text: '9%', value: "1.09"},
        {text: '12%', value: "1.12"},
        {text: '19%', value: "1.19"},
        {text: '20%', value: "1.20"},
        {text: '21%', value: "1.21"}
      ],
      convertVatOptions: {
        "1": "0",
        "1.06": "0.06",
        "1.07": "0.07",
        "1.09": "0.09",
        "1.12": "0.12",
        "1.19": "0.19",
        "1.20": "0.20",
        "1.21": "0.21"
      }
    }
  },
  methods: {
    totalPriceCalc() {
      let price = this.form.piece_price_including_vat,
        quantity = this.form.order_quantity

      if (!!price && !!quantity) {
        let priceInt = parseFloat(price.replace(/[€ ,]/g, '')),
          quantityInt = parseFloat(quantity.replace(/,/g, ''))

        this.form.price_including_vat = (priceInt * quantityInt).toFixed(2)
      }
    },
    addToOrder() {
      this.$validator
      .validate()
      .then(res => {
          if (!!res) {
            this.form.price_including_vat = String(this.form.price_including_vat)
            this.form.piece_price_including_vat = this.form.piece_price_including_vat.replace(/[€ ,]/g, '')
            this.form.order_quantity = this.form.order_quantity.replace(/,/g, '')
            this.form.price_excluding_vat = `${+this.form.piece_price_including_vat / parseFloat(this.form.vat)}`

            this.$emit('onNewProduct', this.form)

            this.form = {}
          } else {
            let errMes = this.errors.items.map(item => item.msg + '\n')
            EventBus.$emit('showAlertModal', errMes.join().replace(/,/g, ''))
          }
        }
      )
    },
    isNumber(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 44 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    }
  },
  components: {
    InputPrice,
    MaskedInput
  }
}
</script>

<style scoped>
.form-control {
  height: 35px !important;
}
</style>
